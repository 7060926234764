@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* fonts */
@font-face {
  font-family: 'Euclid Regular';
  src: url(./assets/fonts/EuclidCircularB-Medium-WebS.woff) format("woff");
}
@font-face {
  font-family: 'Euclid Medium';
  src: url(./assets/fonts/EuclidCircularB-Medium-WebS.woff) format("woff");
}
@font-face {
  font-family: 'Euclid Semibold';
  src: url(./assets/fonts/EuclidCircularB-Semibold-WebS.woff) format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Position zoom controls in the middle of the right side */
.mapboxgl-ctrl-bottom-right {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60%;
  right: 6px;
  bottom: 0; /* Stretch to the bottom */
  z-index: 10;
}

/* Align zoom controls vertically */
.mapboxgl-ctrl-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 32px;
  position: absolute;
  right: 0;
}

/* Ensure the attribution control stays at the bottom of the page */
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
  position: absolute;
  width: max-content;
  bottom: 0px; /* Position it at the bottom of the container with some margin */
  right: 4px; /* Align it to the right */
  z-index: 5; /* Ensure it stays below the zoom controls */
}

.mapboxgl-ctrl-attrib-inner {
  font-size: 10px; /* Make the text smaller */
  padding: 2px 5px; /* Reduce padding */
  line-height: 1.2; 
}

.mapboxgl-ctrl-attrib {
  background-color: rgba(255, 255, 255, 0.8); 
  border-radius: 3px; 
}

/* Mobile mapbox controls */
@media (max-width: 640px) {
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
    position: absolute;
    width: max-content;
    bottom: 70px;
    right: 4px;
    z-index: 5; 
  }
  .mapboxgl-ctrl-logo {
    position: absolute;
    bottom: 137px;
    left: 2px;
  }
}

/* Medium screen mapbox control */
@media (min-width: 640px) and (max-width: 1024px) {
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
    position: absolute;
    width: max-content;
    bottom: 65px;
    right: 4px;
    z-index: 5;
  }
  .mapboxgl-ctrl-logo {
    position: absolute;
    bottom: 137px;
    left: 2px;
  }
}